
import { defineComponent, ref, onMounted, computed } from "vue";
import { useDropzone } from "vue3-dropzone";
import axios from "axios";
import AppService, { renderImageUrl } from "@/core/services/AppService";
import { useStore } from "vuex";

import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "kt-media",
  components: {},
  props: ["type"],
  setup(props) {
    const store = useStore();
    let paged = 1;
    let check_count = 1;
    const listDataMedia = ref();
    const selectMultipleMedia = ref([]);
    const breadcrumbData = ref<any[]>([]);
    const currentParent = computed(() => store.getters["getCurrentParent"]);

    const totalPages = ref();
    const search = ref("");
    const totalMedia = ref();
    const scrollComponent = ref();
    const showEditTitle = ref<boolean[]>([]);
    const isMultiple = props.type == "multi-select";
    const selectImage = (img) => {
      window.parent.postMessage(
        { action: props.type, image: JSON.stringify(img) },
        "*"
      );
      window.parent.postMessage({ mceAction: "close" }, origin);
    };
    const onSelectMultiple = () => {
      window.parent.postMessage(
        {
          action: props.type,
          images: JSON.stringify(selectMultipleMedia.value),
        },
        "*"
      );
    };
    let currentUser = ref<any>({});
    onMounted(async () => {
      currentUser.value = await store.getters.currentUser;
      breadcrumbData.value = await store.getters["getBreadcrumbData"];
      let param = {
        name: search.value,
        page: paged,
        order: "desc",
        order_by: "created_at",
      };
      if (currentParent.value !== "") {
        param["parent"] = currentParent.value;
      }
      await store
        .dispatch(Actions.GET_DATA_MEDIA, AppService.convertParams(param))
        .then(() => {
          listDataMedia.value = store.getters["listDataMedia"];
          totalPages.value = store.getters["getMediaPages"];
          totalMedia.value = store.getters["getTotalMedia"];
        });

      showEditTitle.value = Array(totalMedia.value).fill(false);
      window.addEventListener("scroll", handleScroll);
    });
    const loadMoreMedia = () => {
      paged = paged + 1;
      store
        .dispatch(
          Actions.GET_DATA_MEDIA,
          AppService.convertParams({
            name: search.value,
            page: paged,
            order: "desc",
            order_by: "created_at",
            parent: currentParent.value,
          })
        )
        .then(() => {
          let data = store.getters["listDataMedia"];
          //console.log(data);
          listDataMedia.value.push(...data);
        });
    };
    const updateMedia = async (image, index) => {
      let params = {
        id: image._id,
        name: image.originalname,
      };
      await store.dispatch("RENAME_MEDIA", params).then(() => {
        showEditTitle.value[index] = false;
      });
    };
    const updateCurrentFolder = async (parent) => {
      await store.commit("SetCurrentParent", parent);
      await store.commit("SetBreadcrumbData", breadcrumbData.value);
    };
    const openFolder = async (parent) => {
      //console.log("openFolder", parent);
      breadcrumbData.value.push(parent);
      await updateCurrentFolder(parent._id);
      await store.dispatch(
        Actions.GET_DATA_MEDIA,
        AppService.convertParams({
          name: search.value,
          page: 1,
          order: "desc",
          order_by: "created_at",
          parent: parent._id,
        })
      );
      totalPages.value = await store.getters["getMediaPages"];
      listDataMedia.value = await store.getters["listDataMedia"];
      totalMedia.value = await store.getters["getTotalMedia"];
      showEditTitle.value = Array(totalMedia.value).fill(false);
    };
    const handleScroll = (e) => {
      let element = scrollComponent.value;
      if (!element) return;
      if (element.getBoundingClientRect().bottom < window.innerHeight) {
        //console.log("xxxx");
        if (paged < totalPages.value) {
          loadMoreMedia();
          //console.log("xxxx");
        }
      }
    };
    const changeSelectMultiple = (e) => {
      //console.log(e);
    };
    const createNewFolder = async () => {
      let params = {
        parent: currentParent.value,
        broker_id: currentUser.value._id,
        name: "Thư mục mới",
      };
      await store.dispatch("NEW_FOLDER", params).then((data) => {
        let currentMedia = store.getters.currentMedia;
        listDataMedia.value.unshift(currentMedia);
      });
    };
    const rootFolder = async () => {
      breadcrumbData.value = [];
      await store.commit("SetCurrentParent", "");
      await store.commit("SetBreadcrumbData", []);
      await store.dispatch(
        Actions.GET_DATA_MEDIA,
        AppService.convertParams({
          name: "",
          page: 1,
          order: "desc",
          order_by: "created_at",
          parent: "",
        })
      );
      totalPages.value = await store.getters["getMediaPages"];
      listDataMedia.value = await store.getters["listDataMedia"];
      totalMedia.value = await store.getters["getTotalMedia"];
      showEditTitle.value = Array(totalMedia.value).fill(false);
    };
    const onSearch = async () => {
      paged = 1;
      await store
        .dispatch(
          Actions.GET_DATA_MEDIA,
          AppService.convertParams({
            name: search.value,
            page: paged,
          })
        )
        .then(() => {
          listDataMedia.value = store.getters["listDataMedia"];
        });
    };
    const url = process.env.VUE_APP_MEDIA_URL + "api/v1/upload/files-seo";
    const saveFiles = (files) => {
      const formData = new FormData(); // pass data as a form
      formData.append("folder", currentParent.value);
      for (var x = 0; x < files.length; x++) {
        formData.append("upload_files", files[x]);
      }
      AppService.UploadImage(formData)
        .then(async (response) => {
          await store.dispatch(
            Actions.GET_DATA_MEDIA,
            AppService.convertParams({
              name: search.value,
              page: 1,
              order: "desc",
              order_by: "created_at",
              parent: currentParent.value,
            })
          );
          totalPages.value = await store.getters["getMediaPages"];
          listDataMedia.value = await store.getters["listDataMedia"];
          totalMedia.value = await store.getters["getTotalMedia"];
          showEditTitle.value = Array(totalMedia.value).fill(false);
        })
        .catch((err) => {
          console.error(err);
        });
    };
    const reloadMedia = async () => {
      await store.dispatch(
        Actions.GET_DATA_MEDIA,
        AppService.convertParams({
          name: search.value,
          page: paged,
          order: "desc",
          order_by: "created_at",
          parent: currentParent.value,
        })
      );
      totalPages.value = await store.getters["getMediaPages"];
      listDataMedia.value = await store.getters["listDataMedia"];
      totalMedia.value = await store.getters["getTotalMedia"];
      showEditTitle.value = Array(totalMedia.value).fill(false);
    };
    const deleteMedia = async (id, index) => {
      //console.log(id, index);
      Swal.fire({
        text: "Bạn chắc chắn muốn xóa chứ ?",
        icon: "warning",
        buttonsStyling: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-primary",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await store
            .dispatch("DELETE_MEDIA", id)
            .then(() => {
              delete listDataMedia.value[index];
              delete showEditTitle.value[index];
              //console.log(listDataMedia.value);
              Swal.fire({
                text: "Xóa thành công",
                icon: "success",
                buttonsStyling: false,
                showConfirmButton: false,
                timer: 1000,
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              });
              reloadMedia();
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Vui lòng thử lại!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    function onDrop(acceptFiles, rejectReasons) {
      saveFiles(acceptFiles); // saveFiles as callback
    }

    function itemClick(node) {
      //console.log(node.model.text + " clicked !");
    }

    function showEdit(index) {
      // showEditTitle.value[index] = true
      //console.log(showEditTitle.value);
    }

    function clickBreadcrumb(index) {
      breadcrumbData.value.length = index + 1;
      updateCurrentFolder(breadcrumbData.value[index]._id);
      reloadMedia();
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
    return {
      getRootProps,
      itemClick,
      createNewFolder,
      getInputProps,
      ...rest,
      listDataMedia,
      scrollComponent,
      totalMedia,
      selectMultipleMedia,
      search,
      check_count,
      isMultiple,
      showEditTitle,
      breadcrumbData,
      deleteMedia,
      clickBreadcrumb,
      updateMedia,
      rootFolder,
      openFolder,
      onSearch,
      selectImage,
      showEdit,
      renderImageUrl,
      changeSelectMultiple,
      onSelectMultiple,
    };
  },
  mounted() {
    //Event Listener for Iframe
    window.addEventListener("message", this.iframeEvent, false);
  },
  methods: {
    iframeEvent(event) {
      //Verify App Domain
      // //console.log("Media received:  ", event.data);
      // window.parent.postMessage(
      //   {
      //     mceAction: "execCommand",
      //     cmd: "iframeCommand",
      //     value: "xxxxxxxxxxxxxxxxx",
      //   },
      //   origin
      // );
    },
  },
});
